import React from 'react'
import styled from 'styled-components'
import { useBlogBink } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import Brand from 'components/elements/Vectors/Brand'

// Interface
import { CasesProps } from 'components/flex/Cases/CasesShell'

const StyledBlogGridPost = styled.div`
  background: linear-gradient(90deg, #000000 23.96%, rgba(0, 0, 0, 0) 50%);

  @media (min-width: 992px) {
    min-height: 450px;
  }
`

const Thumbnail = styled(Plaatjie)`
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  right: -10%;
`

const Gradient = styled.div`
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 16%,
    rgba(0, 0, 0, 0.69) 56%,
    rgba(0, 0, 0, 0.69) 76%,
    rgba(0, 0, 0, 0.69) 100%
  );
`

const BrandWrapper = styled.div`
  z-index: 3;

  @media (min-width: 1400px) {
    left: -330px;
    top: 0;
  }

  @media (max-width: 1399px) {
    left: -200px;
  }

  @media (max-width: 991px) {
    bottom: 25px;
    left: -110px;
  }

  @media (max-width: 575px) {
    opacity: 0.2;
  }
`

const Excerpt = styled.div`
  z-index: 3;
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
  z-index: 4;

  & p {
    font-weight: ${({ theme }) => theme.font.weight.regular} !important;
  }

  @media (min-width: 992px) {
    & p {
      font-size: ${({ theme }) => theme.font.size.larger};
    }
  }
`

const CasesSlider: React.FC<CasesProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <StyledBlogGridPost>
            <BrandWrapper className="position-absolute">
              <Brand id="highlighted-cases-brand" width={950} height={722} />
            </BrandWrapper>
            <Gradient className="position-absolute" />
            <Thumbnail
              image={node.recap.thumbnail}
              alt=""
              className="position-absolute"
            />
            <div className="container">
              <div className="row justify-content-end align-items-center">
                <div className="col-sm-7">
                  <Excerpt className="position-relative pt-lg-5">
                    <Content content={node.recap.excerpt} className="pt-5" />
                    <ButtonSecondary
                      to={node.uri}
                      className="mt-5 mb-5 mb-lg-0"
                    >
                      {fields.readmoretext}
                    </ButtonSecondary>
                  </Excerpt>
                </div>
              </div>
            </div>
          </StyledBlogGridPost>
        )
      })}
    </>
  )
}

export default CasesSlider
