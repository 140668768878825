import React from 'react'
import styled from 'styled-components'
import { useBlogBink } from '@ubo/blog-bink'

// Interface
import { CasesProps } from 'components/flex/Cases/CasesShell'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.grey};
`

const Case = styled.div`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & h3 {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.giga};
    }
  }
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    line-height: ${({ theme }) => theme.font.size.larger};
  }
`

const SingleCase: React.FC<CasesProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <Section>
      <div className="container py-5">
        <div className="row justify-content-center py-lg-5">
          <div className="col-lg-10">
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <Case>
                  <div className="row justify-content-between">
                    <div className="col-8 col-sm-5">
                      <h2>KLANTCASE</h2>
                      <h3>{node.title}</h3>
                      <Content
                        content={node.recap.excerpt}
                        className="pt-4 pb-5 pe-sm-4"
                      />
                      <ButtonSecondary to={node.uri}>
                        {fields.readmoretext}
                      </ButtonSecondary>
                    </div>
                    <div className="position-relative col-4 col-sm-7">
                      <Plaatjie
                        image={node.recap.thumbnail}
                        alt=""
                        className="position-absolute"
                      />
                    </div>
                  </div>
                </Case>
              )
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SingleCase
