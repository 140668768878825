import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Interface
import { CasesProps } from 'components/flex/Cases/CasesShell'

// Layout
import CasesSlider from 'components/flex/Cases/Layout/CasesSlider'
import SingleCase from 'components/flex/Cases/Layout/SingleCase'

const Highlighted: React.FC<CasesProps> = ({ fields }) => {
  const {
    allWpCase,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedCasesQueryQuery>(graphql`
    query highlightedCasesQuery {
      allWpCase(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
    }
  `)

  const cases: unknown = allWpCase.edges
  const caseIds = fields.cases?.map((c) => c?.databaseId)

  return (
    <section className="position-relative mb-5 pb-lg-5">
      <BlogBink
        showIds={caseIds as Array<number>}
        posts={cases as BlogBinkPosts}
        id="highlighted-cases"
        limit={Infinity}
      >
        <div className="py-4">
          {fields.appearance === 'default' && (
            <SingleCase fields={fields}>default</SingleCase>
          )}
          {fields.appearance === 'slider' && <CasesSlider fields={fields} />}
        </div>
      </BlogBink>
    </section>
  )
}

export default Highlighted
